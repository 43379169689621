var useReviewInputValidation = function useReviewInputValidation() {
  var chars = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var score = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    _ref$maxChars = _ref.maxChars,
    maxChars = _ref$maxChars === void 0 ? 1000 : _ref$maxChars,
    _ref$minWords = _ref.minWords,
    minWords = _ref$minWords === void 0 ? 2 : _ref$minWords;
  var splittedWords = chars.split(' ').filter(function (word) {
    return word !== '';
  });
  var charsLength = chars.length;
  var colorError = 'text.error';
  var colorDefault = 'text.light';
  if (charsLength === 0 && score <= 3) {
    return {
      message: "Este campo precisa ser preenchido",
      textColor: colorError,
      valid: false
    };
  }
  if (charsLength > 0 && splittedWords.length < minWords) {
    return {
      message: "O coment\xE1rio deve ter ao menos 2 palavras",
      textColor: colorError,
      valid: false
    };
  }
  return {
    message: "".concat(maxChars - charsLength, " caracteres restantes"),
    textColor: colorDefault,
    valid: true
  };
};
export default useReviewInputValidation;