import { useEffect, useRef } from 'react';
var useDidUpdateEffect = function useDidUpdateEffect(fn, observer) {
  var didMountRef = useRef(false);
  useEffect(function () {
    if (didMountRef.current) {
      fn();
    }
    didMountRef.current = true;
  }, observer);
};
export default useDidUpdateEffect;