import useAdaptData from '../components/ecommerce/FinancialServicesNew/useAdaptData';
import getAuthorizationTermText from '../components/ecommerce/FinancialServicesNew/getAuthorizationTermText';
import useLegalInfo from '../components/ecommerce/FinancialServicesNew/useLegalInfo';
import getPurchaseSummary from '../components/ecommerce/FinancialServicesNew/getPurchaseSummary';
import getServiceInfos from '../components/ecommerce/FinancialServicesNew/getServiceInfos';
import usePublishServiceEvents from '../components/ecommerce/FinancialServicesNew/usePublishServiceEvents';
import useActionButtons from './useActionButtons';
import useSelectedServices from './useSelectedServices';
import useServicesSelectedOffer from './useServicesSelectedOffer';
var useFinancialServicesNew = function useFinancialServicesNew(props) {
  var _services$;
  var _useAdaptData = useAdaptData(props),
    cart = _useAdaptData.cart,
    channel = _useAdaptData.channel,
    footerContent = _useAdaptData.footerContent,
    installments = _useAdaptData.installments,
    installmentConditions = _useAdaptData.installmentConditions,
    location = _useAdaptData.location,
    partner = _useAdaptData.partner,
    product = _useAdaptData.product,
    rawProduct = _useAdaptData.rawProduct,
    services = _useAdaptData.services,
    authorizationTermText = _useAdaptData.authorizationTermText;
  var _useSelectedServices = useSelectedServices((_services$ = services[0]) == null ? void 0 : _services$.id),
    selectedServicesIds = _useSelectedServices.selectedServicesIds,
    onSelectedServicesIdsChanged = _useSelectedServices.onSelectedServicesIdsChanged;
  var _useServicesSelectedO = useServicesSelectedOffer(services),
    servicesSelectedOfferId = _useServicesSelectedO.servicesSelectedOfferId,
    onServicesSelectedOfferChange = _useServicesSelectedO.onServicesSelectedOfferChange;
  var _usePublishServiceEve = usePublishServiceEvents({
      selectedServicesIds: selectedServicesIds,
      services: services,
      servicesSelectedOfferId: servicesSelectedOfferId
    }),
    publishAddServicesEvent = _usePublishServiceEve.publishAddServicesEvent,
    publishKnowMore = _usePublishServiceEve.publishKnowMore,
    publishRefuseServiceEvent = _usePublishServiceEve.publishRefuseServiceEvent,
    publishServiceClickEvent = _usePublishServiceEve.publishServiceClickEvent,
    publishServiceLegalInfo = _usePublishServiceEve.publishServiceLegalInfo,
    publishServiceMoreDetails = _usePublishServiceEve.publishServiceMoreDetails,
    publishServiceVideo = _usePublishServiceEve.publishServiceVideo,
    publishServiceOfferChange = _usePublishServiceEve.publishServiceOfferChange;
  var purchaseSummary = getPurchaseSummary({
    installmentConditions: installmentConditions,
    installments: installments,
    product: product,
    selectedServicesIds: selectedServicesIds,
    services: services,
    servicesSelectedOfferId: servicesSelectedOfferId
  });
  var adaptedServices = services == null ? void 0 : services.map(function (service) {
    return getServiceInfos({
      installments: installments,
      onSelectedServicesIdsChanged: onSelectedServicesIdsChanged,
      onServicesSelectedOfferChange: onServicesSelectedOfferChange,
      publishKnowMore: publishKnowMore,
      publishServiceClickEvent: publishServiceClickEvent,
      publishServiceMoreDetails: publishServiceMoreDetails,
      publishServiceOfferChange: publishServiceOfferChange,
      publishServiceVideo: publishServiceVideo,
      selectedServicesIds: selectedServicesIds,
      service: service,
      servicesSelectedOfferId: servicesSelectedOfferId
    });
  });
  var handleAcquireServices = useActionButtons({
    cart: cart,
    channel: channel,
    location: location,
    partner: partner,
    product: product,
    publishAddServicesEvent: publishAddServicesEvent,
    publishRefuseServiceEvent: publishRefuseServiceEvent,
    rawProduct: rawProduct,
    selectedServicesIds: selectedServicesIds,
    servicesSelectedOfferId: servicesSelectedOfferId
  });
  var actionButtonProps = {
    authorizationTermText: getAuthorizationTermText({
      authorizationTermTextRaw: authorizationTermText,
      selectedServicesIds: selectedServicesIds,
      services: services,
      servicesSelectedOfferId: servicesSelectedOfferId
    }),
    handleAcquireServices: handleAcquireServices
  };
  var legalInfos = useLegalInfo({
    footerContent: footerContent,
    publishServiceLegalInfo: publishServiceLegalInfo
  });
  return {
    actionButtonProps: actionButtonProps,
    legalInfos: legalInfos,
    purchaseSummary: purchaseSummary,
    services: adaptedServices
  };
};
export default useFinancialServicesNew;