import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import * as components from './components';
import * as novisual from './novisual';
var ecommerce = components.ecommerce,
  blog = components.blog,
  chat = components.chat;
var componentsBlogInventory = Object.keys(blog).reduce(function (acc, key) {
  return [].concat(_toConsumableArray(acc), [{
    name: key,
    ssr: blog[key].ssr,
    type: 'components/blog'
  }]);
}, []);
var componentsChatInventory = Object.keys(chat).reduce(function (acc, key) {
  return [].concat(_toConsumableArray(acc), [{
    name: key,
    ssr: chat[key].ssr,
    type: 'components/chat'
  }]);
}, []);
var componentsEcommerceInventory = Object.keys(ecommerce).reduce(function (acc, key) {
  return [].concat(_toConsumableArray(acc), [{
    name: key,
    ssr: ecommerce[key].ssr,
    type: 'components/ecommerce'
  }]);
}, []);
var novisualInventory = Object.keys(novisual).reduce(function (acc, key) {
  return [].concat(_toConsumableArray(acc), [{
    name: key,
    ssr: novisual[key].ssr,
    type: 'novisual'
  }]);
}, []);
export var inventory = [].concat(_toConsumableArray(componentsBlogInventory), _toConsumableArray(componentsChatInventory), _toConsumableArray(componentsEcommerceInventory), _toConsumableArray(novisualInventory));
export * from './components/blog';
export * from './components/chat';
export * from './components/ecommerce';
export * from './novisual';
export * from './contexts';