import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';
import { usePublisher } from '@magalu/mixer-publisher';
import { cleanHtml, getFactsheetValueFromSlug } from '@magalu/mixer-utils';
var useProductInfo = function useProductInfo(product) {
  var _bundles$, _ref2;
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _ref = product || {},
    bundles = _ref.bundles,
    factsheet = _ref.factsheet,
    _ref$title = _ref.title,
    title = _ref$title === void 0 ? '' : _ref$title,
    _ref$description = _ref.description,
    description = _ref$description === void 0 ? '' : _ref$description;
  var _useState = useState((bundles == null ? void 0 : (_bundles$ = bundles[0]) == null ? void 0 : _bundles$.id) || null),
    _useState2 = _slicedToArray(_useState, 2),
    bundleId = _useState2[0],
    setBundleId = _useState2[1];
  var handleBundleClickEvent = function handleBundleClickEvent(id) {
    return setBundleId(id);
  };
  useEffect(function () {
    subscribe('product:bundle:click', handleBundleClickEvent);
    return function () {
      unsubscribe('product:bundle:click', handleBundleClickEvent);
    };
  });
  var productInfo = {};
  var currentBundle = (_ref2 = bundles || []) == null ? void 0 : _ref2.find(function (_ref3) {
    var id = _ref3.id;
    return id === bundleId;
  });
  productInfo.title = (currentBundle == null ? void 0 : currentBundle.title) || title;
  var bundleFactsheet = currentBundle == null ? void 0 : currentBundle.factsheet;
  var bundleDescription = bundleFactsheet && getFactsheetValueFromSlug(bundleFactsheet, 'apresentacao');
  var currentDescription = bundleDescription || description;
  productInfo.description = (options == null ? void 0 : options.getRawDescription) ? currentDescription : cleanHtml(currentDescription);
  productInfo.factsheet = bundleFactsheet || factsheet;
  return _extends({}, product, productInfo, {
    bundleId: bundleId
  });
};
export default useProductInfo;