import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import { useMemo, useCallback, useState } from 'react';
var getRecommendedOfferId = function getRecommendedOfferId(offers) {
  return (offers.find(function (offer) {
    return offer.recommended;
  }) || offers[0]).id;
};
var useServicesSelectedOffer = function useServicesSelectedOffer() {
  var services = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var defaultServicesSelectedOfferId = services.reduce(function (acc, curr) {
    var _curr$offers;
    return _extends({}, acc, _defineProperty({}, curr.id, ((_curr$offers = curr.offers) == null ? void 0 : _curr$offers.length) > 0 ? getRecommendedOfferId(curr.offers) : null));
  }, {});
  var _useState = useState(defaultServicesSelectedOfferId),
    _useState2 = _slicedToArray(_useState, 2),
    servicesSelectedOfferId = _useState2[0],
    setServicesSelectedOfferId = _useState2[1];
  var onServicesSelectedOfferChange = useCallback(function (serviceId, offerId) {
    setServicesSelectedOfferId(function (prevState) {
      return _extends({}, prevState, _defineProperty({}, serviceId, offerId));
    });
  }, [setServicesSelectedOfferId]);
  return useMemo(function () {
    return {
      onServicesSelectedOfferChange: onServicesSelectedOfferChange,
      servicesSelectedOfferId: servicesSelectedOfferId
    };
  }, [servicesSelectedOfferId, onServicesSelectedOfferChange]);
};
export default useServicesSelectedOffer;